
import { Component, Vue, Prop } from 'vue-property-decorator'
import { SupplierType } from '@/types/material'

@Component({
  name: 'SuppliermanageDialog'
})
export default class extends Vue {
  @Prop() readonly showDialog!: boolean

  private loading = false
  private tableData: SupplierType[] = []

  private oldData: { [key: number]: SupplierType } = {}
  private isAddType: number | null = null

  created () {
    this.getConfigList()
  }

  getConfigList () {
    this.loading = true
    this.$axios.get(this.$apis.material.selectErpSupplierTypeByPage).then(res => {
      if (res) {
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.push({
        supplierTypeName: '',
        isInput: true
      })
      this.isAddType = this.tableData.length - 1
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (row: SupplierType, index: number) {
    if (this.isAddType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData[index] = {
        ...row,
        isInput: true
      }
      this.oldData[index] = row
      this.isAddType = index
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  onDelete (supplierType: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.material.deleteDataErpSupplierType, { supplierType: supplierType }).then(() => {
        this.$message.success('删除成功')
        this.isAddType = null
        this.getConfigList()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onSave (row: SupplierType) {
    if (row.supplierTypeName) {
      const info = { supplierTypeName: row.supplierTypeName }
      const url = row.supplierType ? this.$apis.material.updateErpSupplierType : this.$apis.material.insertErpSupplierType
      this.$axios.post(url, row.supplierType ? { ...info, supplierType: row.supplierType } : info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.isAddType = null
        this.getConfigList()
      })
    } else {
      this.$message({
        message: '请输入供应商类型完整数据',
        type: 'warning'
      })
    }
  }

  onCancel (row: SupplierType, index: number) {
    this.isAddType = null
    if (row.supplierType) {
      // 是否为编辑
      this.tableData[index].isInput = false
      this.tableData[index].supplierTypeName = this.oldData[index].supplierTypeName // 取消值不变
    } else {
      this.tableData.splice(index, 1)
    }
  }

  closeDialog () {
    this.$emit('update:showDialog', false)
  }
}
